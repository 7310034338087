@use '~@esi/leaf-design-tokens/lib/web/mixins/typography-usage.scss' as
  typography;

.enac-g-main-page-container {
  display: grid;
  // assumes <header>, <main>, <footer> are all siblings and no other top level elements
  grid-template-areas: 'header' 'main' 'footer';
  grid-template-rows: fit-content(2rem) auto fit-content(4rem);
  height: 100vh;
}

header {
  grid-area: header;
}

main {
  grid-area: main;
}

footer {
  grid-area: footer;
}

.enac-g-page-wrap {
  width: 100%;
  max-width: 1380px;
  padding: 0 1rem;
}

@media (min-width: 1400px) {
  .enac-g-page-wrap {
    width: 1380px;
    margin: 0 auto;
  }
}

@media (min-width: 1400px) {
  .enac-g-page-wrap {
    padding: 0;
  }
}

.enac-g-button-group {
  display: flex;
  align-items: center;

  leaf-button {
    margin-top: 0;
    margin-right: 1rem;
  }
}

a.enac-g-router-link {
  text-decoration: none;
  color: inherit;
}

h1 {
  @include typography.leaf-typography-headline-default;
  margin: 0 0 2rem 0;
}

p,
ol li {
  @include typography.leaf-typography-body-large;
}

leaf-heading + p {
  margin-top: 0.5rem;
}

.enac-g-form-centered {
  max-width: 544px;
  margin: 0 auto 0 auto;
}

// todo: make this stuff responsive for smaller screens
.enac-g-wrap-icon-indent {
  max-width: 544px;
  padding-left: 40px;
}

.enac-g-header-icon {
  margin-left: -40px;
  --leaf-icon-height: 32px;
  --leaf-icon-width: 32px;
  color: #008f83; // todo: use leaf color variable here
}

.enac-g-equal-columns {
  display: flex;
}

.enac-g-equal-columns > div {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
}

ul.enac-g-ul-standard {
  padding: 0 0 0 15px;
  margin: 0;
}

form {
  leaf-field {
    display: block;
  }

  leaf-button {
    display: block;
  }
}

form.enac-g-standard-form {
  max-width: 544px;
  leaf-field,
  leaf-select {
    margin-bottom: 1.5rem;
  }
  leaf-button {
    margin-top: 3rem;
  }
}

.enac-g-margin-top-64 {
  margin-top: var(--leaf-spacing-64);
}

.enac-g-margin-bottom-64 {
  margin-bottom: var(--leaf-spacing-64);
}

.enac-g-margin-top-32 {
  margin-top: var(--leaf-spacing-32);
}

.enac-g-margin-bottom-32 {
  margin-bottom: var(--leaf-spacing-32);
}

.enac-g-margin-top-16 {
  margin-top: var(--leaf-spacing-16);
}

.enac-g-margin-top-8 {
  margin-top: var(--leaf-spacing-8);
}

.enac-g-margin-bottom-16 {
  margin-bottom: var(--leaf-spacing-16);
}

.enac-g-color-subtle {
  color: var(--leaf-color-content-subtle);
}

button.enac-g-button-link {
  @include typography.leaf-typography-body-default-link;
  background: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  color: var(--leaf-color-content-link);
  &:hover {
    color: var(--leaf-color-content-link-hover);
    text-decoration: underline;
  }
}

button.enac-g-button-link--large {
  @include typography.leaf-typography-body-large-link;
}

a:not(.enac-g-router-link) {
  @include typography.leaf-typography-body-default-link;
  color: var(--leaf-color-content-link);
}

a:hover:not(.enac-g-router-link) {
  color: var(--leaf-color-content-link-hover);
}

a:active:not(.enac-g-router-link) {
  color: var(--leaf-color-content-link-active);
}

a:visited:not(.enac-g-router-link) {
  // color: var(--leaf-color-content-link-visited);
}

a.enac-g-link-no-visited:visited {
  color: var(--leaf-color-content-link);
}

@media print {
  .enac-g-hide-for-print,
  .enac-g-main-page-container > header,
  .enac-g-main-page-container > footer {
    display: none;
  }
}

// temp leaf hacks
leaf-remove-tag {
  margin-right: 0.5rem;
}
